import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Route, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthApiService } from 'src/app/auth/api/auth-api.service';
import { AppRoutes } from '../enums/app-routes.enum';
import { AuthService } from '../services/auth.service';

@Injectable()
export class PlanGuard {
  constructor(
    private apiService: AuthApiService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  canLoad(route: Route): Observable<boolean> | boolean {
    const organizationId = AuthService.getOrganization()?._id;

    if (!organizationId) {
      this.router.navigate([AppRoutes.Auth.base, AppRoutes.Auth.login]);
    }

    return this.apiService.getOrganizationData(organizationId).pipe(
      map((organizationData) => {
        if (organizationData?.includedModules?.includes(route.path)) {
          return true;
        } else {
          this.snackBar.open(
            `Trying to play smart, huh? Get outta here, you don't have access to "${route.path}"`,
          );

          return false;
        }
      }),
    );
  }
}
