<nav class="d-flex-center-between">
  <div class="title d-inline-flex align-center">
    <mat-icon class="menu-toggler" (click)="toggleSideNav()">menu</mat-icon>
    <span>{{ (title$ | async) || "Goals" }}</span>
  </div>

  <div class="buttons d-inline-flex align-center">
    <div *ngIf="!isProd" class="icon-container">
      <i class="icon-cup-star"></i>
    </div>

    <div class="icon-container">
      <i class="icon-bell" (click)="openNotifications()"> </i>
      <div *ngIf="hasUnReads" class="badge"></div>
    </div>

    <div
      class="icon-container clickable"
      matTooltip="Report a bug or suggest a feature"
    >
      <mat-icon (click)="report()">flag_circle</mat-icon>
    </div>

    <div *ngIf="!isProd" class="icon-container">
      <i class="icon-map"></i>
    </div>
  </div>

  <div *ngIf="notifications$ | async as notifications">
    <div class="notification-panel" *ngIf="showNotifications">
      <div *ngIf="notifications.length; else allCaughtUp">
        <p class="clickable clear-all" (click)="clearAll()">Clear all</p>

        <div
          class="notification-item"
          *ngFor="let notification of notifications"
        >
          <div class="d-flex justify-between">
            <b
              (click)="showNotifications = false"
              class="clickable"
              [routerLink]="['/', appRoutes.Goals.base]"
              [queryParams]="{ goalId: notification.entityId }"
              >{{ notification.title }}</b
            >

            <i
              [matTooltip]="
                notification.read ? 'Mark as unread' : 'Mark as read'
              "
              [ngClass]="{
                'icon-check-circle': notification.read,
                'icon-traffic': !notification.read,
              }"
              class="read clickable"
              (click)="markAsRead(notification._id, !notification.read)"
            ></i>
          </div>
          <sub>{{ notification.ISODate | date }}</sub>
        </div>
      </div>
    </div>
  </div>

  <div
    class="mask-layer"
    (click)="showNotifications = false"
    *ngIf="showNotifications"
  ></div>
</nav>

<ng-template #allCaughtUp>
  <p>
    <i class="icon-clipboard-check p-right-10px"></i>
    <span>You're all caught up</span>
  </p>
</ng-template>
