import { Injectable } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { NotificationApiService } from 'src/app/navigation/api/notification-api.service';
import { LocalStorage } from 'src/core/enums/store-constants.enum';
import { AppNotification } from 'src/core/interfaces/notification.interface';
import {
  NotificationsActions,
  initNotificationsStore,
  removeAllNotifications,
  removeAllNotificationsFromDB,
} from './notifications.actions';

@Injectable()
export class NotificationsEffects implements OnInitEffects {
  loadTemplatesFromDB$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initNotificationsStore),
      mergeMap(() => {
        if (localStorage.getItem(LocalStorage.jwtToken)) {
          return this.apiService.getNotifications().pipe(
            map((notifications: AppNotification[]) =>
              NotificationsActions.loadNotificationsFromDB({
                notifications,
              }),
            ),
            catchError(() => EMPTY),
          );
        }

        return EMPTY;
      }),
    ),
  );

  updateNotificationInDB$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.markAsReadInDB),
      mergeMap(({ notificationId, read }) => {
        return this.apiService.markAsRead(notificationId, read).pipe(
          map(() =>
            NotificationsActions.markAsRead({
              notificationId,
              read,
            }),
          ),
          catchError(() => EMPTY),
        );
      }),
    ),
  );

  removeAllNotificationsFromDB$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeAllNotificationsFromDB),
      mergeMap(() => {
        return this.apiService.removeAll().pipe(
          map(() => removeAllNotifications()),
          catchError(() => EMPTY),
        );
      }),
    ),
  );

  fetchFromDB$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.fetchFromDB),
      mergeMap(({ notificationIds }) => {
        return this.apiService.fetch(notificationIds).pipe(
          map((notifications: AppNotification[]) =>
            NotificationsActions.loadNotificationsFromDB({
              notifications,
            }),
          ),
          catchError(() => EMPTY),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private apiService: NotificationApiService,
  ) {}

  ngrxOnInitEffects(): Action {
    return initNotificationsStore();
  }
}
