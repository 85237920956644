import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '../enums/app-routes.enum';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(): boolean {
    if (this.authService.validateToken()) {
      return true;
    }

    this.router.navigate([AppRoutes.Auth.base]);

    return false;
  }
}
