import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthMode } from '../enums/permissions.enum';
import { AppRoutes } from '../enums/app-routes.enum';

@Injectable()
export class RoleModuleGuard {
  constructor(private router: Router) {}

  canLoad(route: ActivatedRouteSnapshot): boolean {
    const allowedRoles = route.data['allowedRoles'] as AuthMode[];
    const authMode = localStorage.getItem('authMode');

    if ((allowedRoles || [authMode]).includes(authMode)) {
      return true;
    }

    this.router.navigate([AppRoutes.home]);

    return true;
  }
}
