import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { Observable, filter, interval, map, tap } from 'rxjs';
import { AppRoutes, RouteData } from 'src/core/enums/app-routes.enum';
import { AppNotification } from 'src/core/interfaces/notification.interface';
import { AuthService } from 'src/core/services/auth.service';
import { toggleSideNavBar } from 'src/core/store/layout/layout.actions';
import {
  NotificationsActions,
  removeAllNotificationsFromDB,
} from 'src/core/store/notifications/notifications.actions';
import { selectNotificationsFeature } from 'src/core/store/notifications/notifications.selectors';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  private notificationIds: string[] = [];

  public notifications$ = new Observable<readonly AppNotification[]>();
  public appRoutes: typeof AppRoutes = AppRoutes;
  public hasUnReads = false;
  public isProd = environment.production;
  public showNotifications = false;
  public title$ = new Observable<string>();

  constructor(
    private store: Store,
    private router: Router,
  ) {
    this.title$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        const url = this.router.url.split('/')[1];

        return RouteData[url];
      }),
    );
  }

  public openNotifications() {
    this.showNotifications = !this.showNotifications;
  }

  public toggleSideNav() {
    this.store.dispatch(toggleSideNavBar());
  }

  public markAsRead(notificationId: string, read: boolean) {
    this.store.dispatch(
      NotificationsActions.markAsReadInDB({ notificationId, read }),
    );
  }

  public clearAll() {
    this.store.dispatch(removeAllNotificationsFromDB());
  }

  public report(): void {
    const userId = AuthService.getUser()?._id;

    window.open(
      `https://support.onesyncs.com?user=${userId}`,
      '',
      'width=800,height=700,left=100,location=no,toolbar=no,menubar=no',
    );
  }

  ngOnInit(): void {
    interval(60000)
      .pipe(
        tap(() => {
          if (AuthService.getUser()?._id) {
            this.store.dispatch(
              NotificationsActions.fetchFromDB({
                notificationIds: this.notificationIds,
              }),
            );
          }
        }),
      )
      .subscribe();

    this.notifications$ = this.store.select(selectNotificationsFeature).pipe(
      tap((notifications) => {
        this.notificationIds = notifications.map(
          (notification) => notification._id,
        );

        this.hasUnReads = !!notifications.find(
          (notification) => !notification.read,
        );
      }),
    );
  }
}
