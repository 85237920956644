import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/core/services/auth.service';

@Pipe({
  name: 'isDisabledRoute',
})
export class IsDisabledRoutePipe implements PipeTransform {
  private organization = AuthService.getOrganization();
  private isManager = AuthService.getUser()?.isManager;
  private planModules: string[] = ['feed'];

  transform(path: string, managersOnly?: boolean): boolean {
    const module = path.split('/')[0];

    if (managersOnly && !this.isManager) {
      return true;
    }

    if (!this.planModules.includes(module)) {
      return false;
    }

    return !this.organization?.includedModules?.includes(module);
  }
}
