<div [ngSwitch]="isAuthRoute$ | async">
  <div class="auth-layout" *ngSwitchCase="true">
    <div class="main-content d-flex align-center justify center">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div
    class="main-layout"
    [class.side-nav-open]="(layoutState$ | async).isNavbarOpen"
    *ngSwitchCase="false"
  >
    <app-nav></app-nav>

    <div class="d-flex">
      <div
        class="mask"
        *ngIf="(layoutState$ | async).isNavbarOpen"
        (click)="toggleSideNav()"
      ></div>
      <div class="side-nav">
        <app-side-nav-bar></app-side-nav-bar>
      </div>

      <div class="main-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<app-loader></app-loader>
