import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AppRoutes } from '../enums/app-routes.enum';

@Injectable()
export class AuthModuleGuard {
  constructor(private router: Router) {}

  canLoad(): boolean {
    if (AuthService.getUser() === null) {
      this.router.navigate([AppRoutes.Auth.base, AppRoutes.Auth.login]);

      return false;
    }

    return true;
  }
}
