import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Endpoints } from 'src/core/enums/api/api-endpoints.enum';
import { AppNotification } from 'src/core/interfaces/notification.interface';
import { ApiService } from 'src/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService extends ApiService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  /* READ */
  public getNotifications(): Observable<AppNotification[]> {
    return this.http.get<AppNotification[]>(
      this.endpointBuilder(Endpoints.Notification.base),
    );
  }

  public fetch(notificationIds: string[]): Observable<AppNotification[]> {
    return this.http.post<AppNotification[]>(
      this.endpointBuilder(Endpoints.Notification.fetch),
      { notificationIds },
    );
  }

  /* UPDATE */
  public markAsRead(
    notificationId: string,
    read: boolean,
  ): Observable<AppNotification> {
    return this.http.post<AppNotification>(
      this.endpointBuilder(Endpoints.Notification.markAsRead),
      {
        _id: notificationId,
        read,
      },
    );
  }

  /* DELETE */
  public removeAll(): Observable<AppNotification> {
    return this.http.get<AppNotification>(
      this.endpointBuilder(Endpoints.Notification.removeAll),
    );
  }
}
