import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutes } from 'src/core/enums/app-routes.enum';
import { AuthMode } from 'src/core/enums/permissions.enum';
import { AuthModuleGuard } from 'src/core/guards/auth-module.guard';
import { PlanGuard } from 'src/core/guards/plan.guard';
import { RoleModuleGuard } from 'src/core/guards/role-module.guard';
import { InvalidLinkComponent } from 'src/core/shared/components/invalid-link/invalid-link.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { ErrorRouteComponent } from '../core/shared/components/error-route/error-route.component';

const routes: Routes = [
  {
    path: AppRoutes.Auth.base,
    loadChildren: () =>
      import('src/app/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: AppRoutes.Calendar.base,
    loadChildren: () =>
      import('src/app/calendar-view/calendar-view.module').then(
        (m) => m.CalendarViewModule,
      ),
    canActivate: [AuthGuard],
    canLoad: [AuthModuleGuard],
  },
  {
    path: AppRoutes.Dashboard.base,
    loadChildren: () =>
      import('src/app/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
    canActivate: [AuthGuard],
    canLoad: [AuthModuleGuard, RoleModuleGuard],
    data: {
      allowedRoles: [AuthMode.Admin, AuthMode.Manager],
    },
  },
  {
    path: AppRoutes.Feed.base,
    loadChildren: () =>
      import('src/app/feed/feed.module').then((m) => m.FeedModule),
    canActivate: [AuthGuard],
    canLoad: [AuthModuleGuard, PlanGuard],
  },
  {
    path: AppRoutes.Feedback.base,
    loadChildren: () =>
      import('src/app/feedback/feedback.module').then((m) => m.FeedbackModule),
    canActivate: [AuthGuard],
    canLoad: [AuthModuleGuard],
  },
  {
    path: AppRoutes.Goals.base,
    loadChildren: () =>
      import('src/app/goals/goals.module').then((m) => m.GoalsModule),
    canActivate: [AuthGuard],
    canLoad: [AuthModuleGuard],
  },
  {
    path: AppRoutes.Meetings.base,
    loadChildren: () =>
      import('src/app/meeting/meeting.module').then((m) => m.MeetingModule),
    canActivate: [AuthGuard],
    canLoad: [AuthModuleGuard],
  },
  {
    path: AppRoutes.OrganizationSettings.base,
    loadChildren: () =>
      import('src/app/organization-settings/organization-settings.module').then(
        (m) => m.OrganizationSettingsModule,
      ),
    canActivate: [AuthGuard],
    canLoad: [AuthModuleGuard, RoleModuleGuard],
    data: {
      allowedRoles: [AuthMode.Admin],
    },
  },

  {
    path: AppRoutes.PerformanceReview.base,
    loadChildren: () =>
      import('src/app/performance-review/performance-review.module').then(
        (m) => m.PerformanceReviewModule,
      ),
    canActivate: [AuthGuard],
    canLoad: [AuthModuleGuard],
  },
  {
    path: AppRoutes.Profile.base,
    loadChildren: () =>
      import('src/app/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
    canLoad: [AuthModuleGuard],
  },
  {
    path: AppRoutes.Team.base,
    loadChildren: () =>
      import('src/app/team/team.module').then((m) => m.TeamModule),
    canActivate: [AuthGuard],
    canLoad: [AuthModuleGuard],
  },
  {
    path: '',
    redirectTo: '/' + AppRoutes.home,
    pathMatch: 'full',
    canLoad: [AuthModuleGuard],
  },
  {
    path: AppRoutes.invalidLink,
    component: InvalidLinkComponent,
    data: {
      title: 'Invalid Link',
      description: 'The link you are trying to access is invalid.',
    },
  },
  {
    path: '**',
    component: ErrorRouteComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, AuthModuleGuard, PlanGuard, RoleModuleGuard],
})
export class AppRoutingModule {}
