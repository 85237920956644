import { Injectable } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { TeamApiService } from 'src/app/team/api/team.api.service';
import { LocalStorage } from 'src/core/enums/store-constants.enum';
import { TeamData } from 'src/core/interfaces/team/team.interface';
import { TeamsActions, initTeamStore } from './team.actions';

@Injectable()
export class TeamEffects implements OnInitEffects {
  loadTeamsFromDB$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initTeamStore),
      mergeMap(() => {
        if (localStorage.getItem(LocalStorage.jwtToken)) {
          return this.apiService.getTeamMembers().pipe(
            map((response: TeamData[]) =>
              TeamsActions.loadTeamsFromDB({
                teams: response,
                fetched: true,
              }),
            ),
            catchError((e) => {
              console.error('catchError: ', e);

              return EMPTY;
            }),
          );
        }

        return EMPTY;
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private apiService: TeamApiService,
  ) {}

  ngrxOnInitEffects(): Action {
    return initTeamStore();
  }
}
