import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { loaderStoreFeature } from 'src/core/store/loader/loader.selectors';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {
  public visible$ = new Observable<boolean>();

  constructor(
    private store: Store,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.visible$ = this.store.select(loaderStoreFeature).pipe(
      tap(() => {
        this.cdr.detectChanges();
      }),
    );
  }
}
