import { Injectable } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { map, mergeMap, tap } from 'rxjs/operators';

import { AuthApiService } from 'src/app/auth/api/auth-api.service';
import { AuthMode } from 'src/core/enums/permissions.enum';
import { LocalStorage } from 'src/core/enums/store-constants.enum';
import {
  AuthActions,
  AuthModeActions,
  initAuthModeStore,
} from './auth.actions';
import { AuthService } from 'src/core/services/auth.service';

@Injectable()
export class AuthModeEffects implements OnInitEffects {
  initAuthStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAuthModeStore),
      map(() => {
        const localStorageAuthMode: AuthMode =
          (localStorage.getItem(LocalStorage.authMode) as AuthMode) ||
          AuthMode.Personal;

        return AuthModeActions.setMode({ mode: localStorageAuthMode });
      }),
    ),
  );

  updateJWTToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthModeActions.updateJWTToken),
      mergeMap(({ mode }) =>
        this.apiService.updateAuthMode(mode).pipe(
          tap((token) => {
            localStorage.setItem(LocalStorage.authMode, mode);
            localStorage.setItem(LocalStorage.jwtToken, token);

            window.location.reload();
          }),
          map(() => AuthModeActions.setMode({ mode })),
        ),
      ),
    ),
  );

  setAITokensLeft$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.setAITokensLeft),
        tap(({ tokensLeft }) => {
          AuthService.setTokensLeft(tokensLeft);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private readonly apiService: AuthApiService,
  ) {}

  ngrxOnInitEffects(): Action {
    return initAuthModeStore();
  }
}
