<div
  class="side-nav-bar"
  *ngIf="authMode$ | async as authMode"
  [ngClass]="authMode"
>
  <div
    class="logo d-flex-column align-center justify-center white-bg"
    [ngClass]="{ 'white-bg': organization?.logo }"
    [routerLink]="appRoutes.home"
  >
    <img
      [class.default]="!organization?.logo"
      [src]="
        organization?.logo
          ? '/assets/logos/' + organization?.logo
          : '/assets/logo.svg'
      "
      alt="logo"
    />
  </div>

  <ul class="nav-links d-flex justify-center">
    <li
      routerLinkActive="active-link"
      *ngFor="let item of navConfig"
      [routerLink]="item.path"
      [routerLinkActiveOptions]="{ exact: item.isExactLink }"
      class="nav-link d-flex-center2"
    >
      <div class="wrap-icons">
        <app-icon [name]="item.icon"></app-icon>
      </div>

      <b>{{ item.text }}</b>
    </li>
  </ul>

  <div
    *ifPermission="[appPermission.authSwitchMode]; else navigateToProfile"
    class="nav-link profile-menu"
    [matMenuTriggerFor]="menu"
  >
    <div class="wrap-icons">
      <app-icon name="user-circle"></app-icon>
    </div>

    <b>Profile</b>
  </div>

  <mat-menu #menu="matMenu">
    <ng-template ngFor let-mode [ngForOf]="typeofAuthMode | keyvalue">
      <button
        mat-menu-item
        *ngIf="authMode !== mode.value"
        (click)="setAuthMode(mode.value)"
      >
        {{ mode.key }}
      </button>
    </ng-template>

    <button mat-menu-item (click)="navigateToOwnProfile()">My profile</button>
  </mat-menu>
</div>

<ng-template #navigateToProfile>
  <div
    class="nav-link profile-menu"
    routerLinkActive="active-link"
    [routerLink]="appRoutes.Profile.base"
  >
    <div class="wrap-icons">
      <app-icon name="user-circle"></app-icon>
    </div>

    <b>Profile</b>
  </div>
</ng-template>
